import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { ADMIN_NAV_MAP } from "discourse/lib/sidebar/admin-nav-map";
export default class ToggleAllSections extends Component {
    @service
    sidebarState;
    @service
    keyValueStore;
    get allSectionsExpanded() {
        return ADMIN_NAV_MAP.every((adminNav1)=>{
            return !this.sidebarState.collapsedSections.has(`sidebar-section-${this.sidebarState.currentPanel.key}-${adminNav1.name}-collapsed`);
        });
    }
    get title() {
        return this.allSectionsExpanded ? "admin.collapse_all_sections" : "admin.expand_all_sections";
    }
    get icon() {
        return this.allSectionsExpanded ? "discourse-chevron-collapse" : "discourse-chevron-expand";
    }
    @action
    toggleAllSections() {
        const collapse1 = this.allSectionsExpanded;
        ADMIN_NAV_MAP.forEach((adminNav1)=>{
            const key1 = `${this.sidebarState.currentPanel.key}-${adminNav1.name}`;
            if (collapse1) {
                this.sidebarState.collapseSection(key1);
            } else {
                this.sidebarState.expandSection(key1);
            }
        });
    }
    static{
        template(`
    <DButton
      @action={{this.toggleAllSections}}
      @icon={{this.icon}}
      @title={{this.title}}
      class="btn-transparent sidebar-toggle-all-sections"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
