import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { Input } from "@ember/component";
import { concat, fn, get } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import { service } from "@ember/service";
import { gt, includes, not } from "truth-helpers";
import EditNavigationMenuModal from "discourse/components/sidebar/edit-navigation-menu/modal";
import borderColor from "discourse/helpers/border-color";
import categoryBadge from "discourse/helpers/category-badge";
import dirSpan from "discourse/helpers/dir-span";
import loadingSpinner from "discourse/helpers/loading-spinner";
import { popupAjaxError } from "discourse/lib/ajax-error";
import Category from "discourse/models/category";
import { INPUT_DELAY } from "discourse-common/config/environment";
import i18n from "discourse-common/helpers/i18n";
import discourseDebounce from "discourse-common/lib/debounce";
// Given a list, break into chunks starting a new chunk whenever the predicate
// is true for an element.
function splitWhere(elements1, f1) {
    return elements1.reduce((acc1, el1, i1)=>{
        if (i1 === 0 || f1(el1)) {
            acc1.push([]);
        }
        acc1[acc1.length - 1].push(el1);
        return acc1;
    }, []);
}
function findAncestors(categories1) {
    let categoriesToCheck1 = categories1;
    const ancestors1 = [];
    for(let i1 = 0; i1 < 3; i1++){
        categoriesToCheck1 = categoriesToCheck1.map((c1)=>Category.findById(c1.parent_category_id)).filter(Boolean).uniqBy((c1)=>c1.id);
        ancestors1.push(...categoriesToCheck1);
    }
    return ancestors1;
}
function applyMode(mode1, categories1, selectedSidebarCategoryIds1) {
    return categories1.filter((c1)=>{
        switch(mode1){
            case "everything":
                return true;
            case "only-selected":
                return selectedSidebarCategoryIds1.includes(c1.id);
            case "only-unselected":
                return !selectedSidebarCategoryIds1.includes(c1.id);
        }
    });
}
export default class SidebarEditNavigationMenuCategoriesModal extends Component {
    @service
    currentUser;
    @service
    site;
    @service
    siteSettings;
    @tracked
    initialLoad = true;
    @tracked
    filteredCategoriesGroupings = [];
    @tracked
    filteredCategoryIds = [];
    // TODO: tracked array, no ember array methods
    @tracked
    selectedSidebarCategoryIds = [
        ...this.currentUser.sidebar_category_ids
    ];
    constructor(){
        super(...arguments);
        this.observer = new IntersectionObserver((entries1)=>{
            if (entries1.some((entry1)=>entry1.isIntersecting)) {
                this.observer.disconnect();
                this.loadMore();
            }
        }, {
            threshold: 1.0
        });
        this.processing = false;
        this.setFilterAndMode("", "everything");
    }
    setFilteredCategories(categories1) {
        this.filteredCategories = categories1;
        const ancestors1 = findAncestors(categories1);
        const allCategories1 = categories1.concat(ancestors1).uniqBy((c1)=>c1.id);
        this.filteredCategoriesGroupings = splitWhere(Category.sortCategories(allCategories1), (category1)=>category1.parent_category_id === undefined);
        this.filteredCategoryIds = categories1.map((c1)=>c1.id);
    }
    concatFilteredCategories(categories1) {
        this.setFilteredCategories(this.filteredCategories.concat(categories1));
    }
    setFetchedCategories(mode1, categories1) {
        this.setFilteredCategories(applyMode(mode1, categories1, this.selectedSidebarCategoryIds));
    }
    concatFetchedCategories(mode1, categories1) {
        this.concatFilteredCategories(applyMode(mode1, categories1, this.selectedSidebarCategoryIds));
    }
    @action
    didInsert(element1) {
        this.observer.disconnect();
        this.observer.observe(element1);
    }
    async searchCategories(filter1, mode1) {
        if (filter1 === "" && mode1 === "only-selected") {
            this.setFilteredCategories(await Category.asyncFindByIds(this.selectedSidebarCategoryIds));
            this.loadedPage = null;
            this.hasMorePages = false;
        } else {
            const { categories: categories1 } = await Category.asyncSearch(filter1, {
                includeAncestors: true,
                includeUncategorized: false
            });
            this.setFetchedCategories(mode1, categories1);
            this.loadedPage = 1;
            this.hasMorePages = true;
        }
    }
    async setFilterAndMode(newFilter1, newMode1) {
        this.requestedFilter = newFilter1;
        this.requestedMode = newMode1;
        if (!this.processing) {
            this.processing = true;
            try {
                while(this.loadedFilter !== this.requestedFilter || this.loadedMode !== this.requestedMode){
                    const filter1 = this.requestedFilter;
                    const mode1 = this.requestedMode;
                    await this.searchCategories(filter1, mode1);
                    this.loadedFilter = filter1;
                    this.loadedMode = mode1;
                    this.initialLoad = false;
                }
            } finally{
                this.processing = false;
            }
        }
    }
    async loadMore() {
        if (!this.processing && this.hasMorePages) {
            this.processing = true;
            try {
                const page1 = this.loadedPage + 1;
                const { categories: categories1 } = await Category.asyncSearch(this.requestedFilter, {
                    includeAncestors: true,
                    includeUncategorized: false,
                    page: page1
                });
                this.loadedPage = page1;
                if (categories1.length === 0) {
                    this.hasMorePages = false;
                } else {
                    this.concatFetchedCategories(this.requestedMode, categories1);
                }
            } finally{
                this.processing = false;
            }
            if (this.loadedFilter !== this.requestedFilter || this.loadedMode !== this.requestedMode) {
                await this.setFilterAndMode(this.requestedFilter, this.requestedMode);
            }
        }
    }
    debouncedSetFilterAndMode(filter1, mode1) {
        discourseDebounce(this, this.setFilterAndMode, filter1, mode1, INPUT_DELAY);
    }
    @action
    resetFilter() {
        this.debouncedSetFilterAndMode(this.requestedFilter, "everything");
    }
    @action
    filterSelected() {
        this.debouncedSetFilterAndMode(this.requestedFilter, "only-selected");
    }
    @action
    filterUnselected() {
        this.debouncedSetFilterAndMode(this.requestedFilter, "only-unselected");
    }
    @action
    onFilterInput(filter1) {
        this.debouncedSetFilterAndMode(filter1.toLowerCase().trim(), this.requestedMode);
    }
    @action
    deselectAll() {
        this.selectedSidebarCategoryIds.clear();
    }
    @action
    toggleCategory(categoryId1) {
        if (this.selectedSidebarCategoryIds.includes(categoryId1)) {
            this.selectedSidebarCategoryIds.removeObject(categoryId1);
        } else {
            this.selectedSidebarCategoryIds.addObject(categoryId1);
        }
    }
    @action
    resetToDefaults() {
        this.selectedSidebarCategoryIds = this.siteSettings.default_navigation_menu_categories.split("|").map((id1)=>parseInt(id1, 10));
    }
    @action
    save() {
        this.saving = true;
        const initialSidebarCategoryIds1 = this.currentUser.sidebar_category_ids;
        this.currentUser.set("sidebar_category_ids", this.selectedSidebarCategoryIds);
        this.currentUser.save([
            "sidebar_category_ids"
        ]).then(()=>{
            this.args.closeModal();
        }).catch((error1)=>{
            this.currentUser.set("sidebar_category_ids", initialSidebarCategoryIds1);
            popupAjaxError(error1);
        }).finally(()=>{
            this.saving = false;
        });
    }
    static{
        template(`
    <EditNavigationMenuModal
      @title="sidebar.categories_form_modal.title"
      @disableSaveButton={{this.saving}}
      @save={{this.save}}
      @showResetDefaultsButton={{gt
        this.siteSettings.default_navigation_menu_categories.length
        0
      }}
      @resetToDefaults={{this.resetToDefaults}}
      @deselectAll={{this.deselectAll}}
      @deselectAllText={{i18n "sidebar.categories_form_modal.subtitle.text"}}
      @inputFilterPlaceholder={{i18n
        "sidebar.categories_form_modal.filter_placeholder"
      }}
      @onFilterInput={{this.onFilterInput}}
      @resetFilter={{this.resetFilter}}
      @filterSelected={{this.filterSelected}}
      @filterUnselected={{this.filterUnselected}}
      @closeModal={{@closeModal}}
      class="sidebar__edit-navigation-menu__categories-modal"
    >
      <form class="sidebar-categories-form">
        {{#if this.initialLoad}}
          <div class="sidebar-categories-form__loading">
            {{loadingSpinner size="small"}}
          </div>
        {{else}}
          {{#each this.filteredCategoriesGroupings as |categories|}}
            <div
              {{didInsert this.didInsert}}
              style={{borderColor (get categories "0.color") "left"}}
              class="sidebar-categories-form__row"
            >
              {{#each categories as |category|}}
                <div
                  data-category-id={{category.id}}
                  data-category-level={{category.level}}
                  class="sidebar-categories-form__category-row"
                >
                  <label
                    for={{concat
                      "sidebar-categories-form__input--"
                      category.id
                    }}
                    class="sidebar-categories-form__category-label"
                  >
                    <div class="sidebar-categories-form__category-wrapper">
                      <div class="sidebar-categories-form__category-badge">
                        {{categoryBadge category}}
                      </div>

                      {{#unless category.parentCategory}}
                        <div
                          class="sidebar-categories-form__category-description"
                        >
                          {{dirSpan
                            category.description_excerpt
                            htmlSafe="true"
                          }}
                        </div>
                      {{/unless}}
                    </div>

                    <Input
                      {{on "click" (fn this.toggleCategory category.id)}}
                      @type="checkbox"
                      @checked={{includes
                        this.selectedSidebarCategoryIds
                        category.id
                      }}
                      disabled={{not
                        (includes this.filteredCategoryIds category.id)
                      }}
                      id={{concat
                        "sidebar-categories-form__input--"
                        category.id
                      }}
                      class="sidebar-categories-form__input"
                    />
                  </label>
                </div>
              {{/each}}
            </div>
          {{else}}
            <div class="sidebar-categories-form__no-categories">
              {{i18n "sidebar.categories_form_modal.no_categories"}}
            </div>
          {{/each}}
        {{/if}}
      </form>
    </EditNavigationMenuModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
